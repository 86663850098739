var userAgent = window.navigator.userAgent.toLowerCase();
if(userAgent.indexOf('trident') != -1 || userAgent.indexOf('msie') != -1) {
	$('body').addClass('ie');
}

// グロナビの開閉
$('[data-theme-script="toggleNavMenu"]')
	.on('click touch',function(e){
		$('body').toggleClass('show-navmenu');
	}
);

// FAQの開閉
$('[data-theme-script="toggleThisFaq"')
	.on('click touch',function(e){
		var $this=$(this);
		var $li=$this.parents('.index-faq-list-item');
		var $q=$li.find('.index-faq-list-item-q');
		if($li.hasClass('show-faq')){
			$li.removeClass('show-faq');
			$q.stop().slideUp(500,'swing');
		}else{
			$li.addClass('show-faq');
			$q.stop().slideDown(500,'swing');
		}
	}
);

// プランの開閉
$('[data-theme-script="toggleExpandbox"]')
	.on('click touch',function(e){
		var $this=$(this);
		var $expandbox=$this.parents('.index-price-plan-expandbox');
		var $detail=$expandbox.find('.index-price-plan-expandbox-detail');
		if($expandbox.hasClass('show-expandbox')){
			$expandbox.removeClass('show-expandbox');
			$detail.stop().slideUp(500,'swing');
		}else{
			$expandbox.addClass('show-expandbox');
			$detail.stop().slideDown(500,'swing');
		}
	}
);

// アンカーリンクへスクロールで移動
var toScroll=function($element){
	var headerHeight=$('#header').height();
	var navigationHeight=$('#navigation').height();
	var property=window.getComputedStyle($('body')[0],'::before').getPropertyValue('content');
	if(property.match(/lg/)){
		var height=headerHeight+navigationHeight;
	}else{
		var height=headerHeight;
		// SPでナビを開いていたら閉じる
		$('body').removeClass('show-navmenu');
	};

	// 対象セクションまでスクロール
	$('html,body').stop().animate({
		scrollTop:$element.offset().top-height
	},500,'swing');
	return false;
}

var anchorLinkScroll=function(e){
	var $this=$(this);
	var href=$this.attr('href').split('#');
	var id='#'+href[1];
	var $element=$(id);
	if($element.length){
		// フォーム以外のページならスクロース、それ以外ならリンク遷移
		e.preventDefault();
		return toScroll($element);
	}
}

$('[href="/#about"]').on('click',anchorLinkScroll);
$('[href="/#service"]').on('click',anchorLinkScroll);
$('[href="/#flow"]').on('click',anchorLinkScroll);
$('[href="/#price"]').on('click',anchorLinkScroll);
$('[href="/#guide"]').on('click',anchorLinkScroll);
$('[href="/#plan-standard"]').on('click',anchorLinkScroll);
$('[href="/#plan-dream"]').on('click',anchorLinkScroll);

if(location.hash){
	var $element=$(location.hash);
	if($element.length){
		// フォーム以外のページならスクロース、それ以外ならリンク遷移
		$(window).on('load',function(){
			toScroll($element);
		});
	}
}

var ua = navigator.userAgent;

if (ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
	var $viewport = $('meta[name="viewport"]');
	$viewport.attr('content', 'width=device-width, initial-scale=1.0');
} else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
	var $viewport = $('meta[name="viewport"]');
	$viewport.attr('content', 'width=1050px,user-scalable=no,maximum-scale=1');
} else if ($('window').width() < 720) {
	var scale = Math.round($('body').width() / 60);
	var $viewport = $('meta[name="viewport"]');
	$viewport.attr('content', 'width=600, initial-scale=' + scale * 0.1);
} else if ($('window').width() < 1050 && $('window').width() > 720) {
	var scale = Math.round($('body').width() / 120);
	var $viewport = $('meta[name="viewport"]');
	$viewport.attr('content', 'width=1050, initial-scale=' + scale * 0.1);
}